@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/syne/Syne-Regular.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: bold;
  font-weight: 500;
  src: url('/fonts/syne/Syne-Medium.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: bold;
  font-weight: 600;
  src: url('/fonts/syne/Syne-SemiBold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: bold;
  font-weight: 700;
  src: url('/fonts/syne/Syne-Bold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: bold;
  font-weight: 800;
  src: url('/fonts/syne/Syne-ExtraBold.ttf');
}

@font-face {
  font-family: 'Manrope VF';
  src: url(/fonts/manrope/Manrope[wght].woff2) format('woff2-variations');
  font-style: normal;
  font-variation-settings: 'wght' 500;
  font-variation-ligatures: normal;
  font-weight: 200 800;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-ExtraLight.woff2) format('woff2');
  font-weight: 200;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-SemiBold.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-Bold.woff2) format('woff2');
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-family: Manrope;
  src: url(/fonts/manrope/Manrope-ExtraBold.woff2) format('woff2');
  font-weight: 800;
}
