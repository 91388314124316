$blue: #22d7ee;
$dark-blue: #06b6d4;
$darkest-blue: #0e7490;
$dark-coal: #0f172a;
$dark-grey: #cbd5e1;
$light-grey: #e2e8f0;
$darkest-grey: #475569;
$white: #ffffff;

$primary: $blue;
$text-color-primary-dark: $dark-coal;
$text-color-secondary-dark: $darkest-grey;
$text-color-primary-light: $white;
$text-color-secondary-light: $white;

$body-font-family: 'Manrope VF', Manrope -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$title-font-family: Syne;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
